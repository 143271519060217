<template>
  <div v-if="question" v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-5">
      <h4>{{question.order}}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="row">
      <div v-for="(option) in results" :key="option.order + 1" class="col-12 col-sm-6 col-md-2 "
           @click="handleOptions(option)">
        <div
          class="item p-2 my-2 d-flex flex-column justify-content-center align-items-center"
          :class="{'selected': option.value === true}">
          <img :src="require(`../../assets/icons/${option.img}`)" width="50px" alt="">
          <p class="m-0 text-center" >{{ option.title }}</p>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-5">
        <div class="form-group wet-form mb-4 password">
          <label class="form-label">{{ question.options.alternative.text }}</label>
          <input v-model="copyAlternative" :placeholder="question.options.alternative.placeholder"
                 @input="(e) => changeInput(e.target.value)" class="form-control" required>
        </div>
      </div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="handleValidation" @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store';
import {multipleWithImagesInitialData} from "../../utils/questionsInitialData";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "MultipleWithImages",
  data() {
    return {
      results: [],
      copyAlternative: '',
      question: multipleWithImagesInitialData,
      order: 1,
    }
  },
  computed: {
    handleValidation() {
      return this.results.length < 1 && !this.copyAlternative
    }
  },
  mounted() {
    this.getQuestionData();
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      this.results = [...this.question.options.values];
      this.copyAlternative = this.question.options.alternative.value;
      if (store.state.currentQuestion.result.answer.options.length > 0) {
        this.results = store.state.currentQuestion.result.answer.options
      }
    }
  },
  methods: {
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: {
          options: this.results,
          alternative: this.alternative,
        }
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
    changeInput(value) {
      this.$emit('changeAlternative', value);
    },
    handleOptions(el) {
        const position = this.results.findIndex(x => x === el);
        this.results[position].value = !this.results[position].value
    },
  }
}
</script>

<style scoped lang="scss">
.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon   {
      fill: white ;
    }
  }
}


.item {
  min-height: 115px;
  border: 1px solid #23af4d;
  border-radius: 15px;

  &:hover {
    cursor: pointer;
    transition: background-color 200ms ease;
    color: white !important;
    background-color: rgba(35, 175, 77, 1);
    fill: white !important;
  }
}

.selected {
  background-color: rgba(35, 175, 77, 1);
  color: white !important;
  fill: white !important;
}

.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

p {
  font-size: 0.8rem !important;
  margin-top: 0.7rem !important;
}
</style>
